/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { getOneElastic } from '@hmn/rtl-web-core/hooks'
import { handleError, returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import { ClockNetIcon, PinNetIcon } from '../Icon'
import styles from './LiveWidget.net.style'

const LiveWidgetStyled = styled.div(props => ({
    ...styles(props)
}))

// function formatTime(dateStr) {
//     const [datePart, timePartWithOffset] = dateStr.split('T')
//     const [year, month, day] = datePart.split('-')
//     const [timePart] = timePartWithOffset.split(/[+-]/)
//     const [hours, minutes] = timePart.split(':')

//     return `${day}. ${month}. ${year} u ${hours}:${minutes}`
// }

function formatTimeRange(startTimeStr, endTimeStr) {
    if (!startTimeStr && !endTimeStr) {
        return '' // If both are missing
    }

    const parseDateTime = dateTimeStr => {
        if (!dateTimeStr) {
            return null
        }
        const [datePart, timeWithOffset] = dateTimeStr.split('T')
        const [year, month, day] = datePart.split('-')
        const [timePart] = timeWithOffset.split(/[+-]/)
        const [hours, minutes] = timePart.split(':')
        return { year, month, day, hours, minutes }
    }

    // format one of time string
    const formatDateTime = ({ day, month, year, hours, minutes }) => `${day}. ${month}. ${year} u ${hours}:${minutes}h`

    const start = parseDateTime(startTimeStr)
    const end = parseDateTime(endTimeStr)

    // if one of dates not set
    if (start && !end) {
        return formatDateTime(start)
    }

    if (!start && end) {
        return formatDateTime(end)
    }

    // if the dates are the same
    if (start.day === end.day && start.month === end.month && start.year === end.year) {
        return `
        ${start.day}. ${start.month}. ${start.year} od ${start.hours}:${start.minutes}h - ${end.hours}:${end.minutes}h
        `
    }

    // default value
    return `${formatDateTime(start)} - ${formatDateTime(end)}`
}

const createParagraphs = data =>
    data &&
    data.split(';').map(item => (
        <p className="live-widget__scorer" key={item}>
            {item.trim()}
        </p>
    ))

export default function LiveWidget({ initialLiveData, articleID }) {
    const [liveData, setLiveData] = useState(initialLiveData)

    const {
        live_location: liveLocation,
        live_coverage_start_time: liveCoverageStartTime,
        live_coverage_end_time: liveCoverageEndTime,
        live_competition: liveCompetition,
        live_label_active: liveLabelActive,
        live_score: liveScore,
        live_hometeam: liveHometeam,
        live_guestteam: liveGuestteam,
        live_homescorers: liveHomeScorers,
        live_guestscorers: liveGuestScorers
    } = liveData

    const homeTeamScore = liveScore?.split(':')[0]
    const guestTeamScore = liveScore?.split(':')[1]

    const hasResult = homeTeamScore && guestTeamScore

    async function updateLive() {
        try {
            const response = await getOneElastic(`entity/${articleID}`, { preview: true })
            const updatedLiveData = response?.data?.extended_attributes
            if (updatedLiveData) {
                setLiveData(updatedLiveData)
            }
        } catch (error) {
            handleError(returnEmptyOn4xx)(error)
        }
    }

    useEffect(() => {
        let intervalId

        if (liveLabelActive) {
            intervalId = setInterval(() => {
                updateLive()
            }, 15000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
    }, [liveLabelActive])

    return (
        <LiveWidgetStyled>
            {liveLabelActive && (
                <p className="live-widget__live-label">
                    <span className="dot" /> UŽIVO
                </p>
            )}

            <div className="live-widget__header">
                {liveCompetition && <div className="live-widget__header-title">{liveCompetition}</div>}
                <div className="live-widget__header-details">
                    {liveLocation && (
                        <span>
                            <PinNetIcon width="10" height="12" viewBox="0 0 12 14" />
                            {liveLocation}
                        </span>
                    )}
                    {/* {(liveCoverageStartTime || liveCoverageEndTime) && (
                        <span>
                            <ClockNetIcon width="12" height="12" viewBox="0 0 12 12" />
                            {formatTime(liveCoverageStartTime)}h - {formatTime(liveCoverageEndTime)}h
                        </span>
                    )} */}

                    {(liveCoverageStartTime || liveCoverageEndTime) && (
                        <span>
                            <ClockNetIcon width="12" height="12" viewBox="0 0 12 12" />
                            {formatTimeRange(liveCoverageStartTime, liveCoverageEndTime)}
                        </span>
                    )}
                </div>
            </div>

            <div className="live-widget__grid">
                {hasResult && (
                    <>
                        <div className="live-widget__result-number">{homeTeamScore}</div>
                        <div className="live-widget__result-number">{guestTeamScore}</div>
                    </>
                )}

                <div className="live-widget__team-name">{liveHometeam}</div>
                <div className="live-widget__team-name">{liveGuestteam}</div>

                <div className="live-widget__scorers live-widget__scorers--home">
                    {createParagraphs(liveHomeScorers)}
                </div>
                <div className="live-widget__scorers live-widget__scorers--guest">
                    {createParagraphs(liveGuestScorers)}
                </div>
            </div>
        </LiveWidgetStyled>
    )
}

LiveWidget.propTypes = {
    initialLiveData: PropTypes.shape({
        live_location: PropTypes.string,
        live_coverage_start_time: PropTypes.string,
        live_coverage_end_time: PropTypes.string,
        live_competition: PropTypes.string,
        live_label_active: PropTypes.bool,
        live_score: PropTypes.string,
        live_hometeam: PropTypes.string,
        live_guestteam: PropTypes.string,
        live_homescorers: PropTypes.string,
        live_guestscorers: PropTypes.string
    }).isRequired,
    articleID: PropTypes.string.isRequired
}
